<template>
  <div>
    <a href="http://118.89.117.45:9080/systemData/dataModel" target="_blank" >Redirect</a>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data(){
    return{
    }
  },
  created() {
    let token=window.localStorage.getItem("jnpf_token");
    console.log('B',token);
    let params=new URLSearchParams();
    params.append('account','admin');
    params.append('password','e10adc3949ba59abbe56e057f20f883e');
    this.whale.remote.getThird({
      url:"http://118.89.117.45:9080/api/oauth/Login",
      params:params,
      finally(res){
        console.log('A',res.data.token);
        //this.headers.add("token",res.data.token);
        // this.http.headers.common['token'] = res.data.token;
      }
    })
  },
  methods:{

  }
}
</script>

